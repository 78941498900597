import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default {
  namespaced: true,
  state: {
    loadingModule: {
      text: '', active: false, percent: 0, showProgress: false,
    },
    equipos_laboratorio: null,
    ensayos_software: null,
    medios_ensayos: null,
    matrices: null,
    niveles_muestras: [],
    mixes: null,
    tipo_deteriorantes: null,
    parametros_ensayos: null,
    isLab: false,
    laboratorios: null,
    programas_taag: null,
    informacion_bitacora: null,
    pocillos_interpretacion: [],
    pocillos_bitacora: [],
    maxFiles: 0,
    archivos_requeridos: null,
    controles: null,
    tipoInterpretacion: 3,
    equipoLaboratorioSelected: null,
    selectedFiles: [],
    bitacora_id: null,
    programa_id: null,
    showAnalisis: true,
    configPocillos: [
      {
        equipo_id: 1,
        positions: 16,
        letters: [' '],
        coords: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16'],
      },
      {
        equipo_id: 2,
        positions: 96,
        letters: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'],
        coords: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
      },
      {
        equipo_id: 3,
        positions: 96,
        letters: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'],
        coords: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
      },
    ],
    showEditResults: false,
    boolEditResults: false,
    infoUser: null,
    files_names_system: null,
    versionFile: null,
    programaSelected: null,
    sampleTemplate: {
      coordinate: '',
      position:	'',
      ot:	null,
      medio_id:	null,
      medio_prefix:	null,
      medio_name:	null,
      muestra_id:	null,
      ensayo_id:	null,
      grupo_ensayo_id:	null,
      grupo_ensayo_mix:	null,
      ensayo_muestra_id:	null,
      ensayo_easypcr_id:	null,
      matriz_id:	null,
      descripcion:	null,
      deteriorado:	null,
      mix_id:	null,
      mix_prefix:	null,
      nivel_muestra:	null,
      nivel_muestra_names:	null,
      type_semicuantificado:	0,
      is_control:	0,
      control_id:	null,
      bitacora_muestra_id:	null,
      uid:	null,
      parametros_interpretacion:	null,
      txt_range: null,
      matriz_name: null,
      m_matriz: 'otro',
      m_freq: 'Otro',
      prefijo_muestra: null,
      ensayo_name: null,
      zona: null,
      cliente_nombre: null,
      local_nombre: null,
      cliente_id_muestra: 0,
      file_resultado: null,
      control_muestra_ot: null,
    },
    canalesSoftwares: [],
    softwares: [],
    pocillosSelected: [],
    pocilloDinamic: null,
    id_interpretacion: null,
    codigos_software: null,
  },
  mutations: {
    setStateLoadingModule(state, data) {
      state.loadingModule.text = data.text;
      state.loadingModule.active = data.active;
      state.loadingModule.percent = data.percent;
      state.loadingModule.showProgress = data.showProgress;
    },
    setCatalogs(state, data) {
      console.log({ setCatalogs: data });
      state.equipos_laboratorio = data.equiposLaboratorio;
      state.ensayos_software = data.ensayos;
      state.medios_ensayos = data.medios_ensayos;
      state.matrices = data.matrices;
      state.niveles_muestras = data.niveles_muestra;
      state.mixes = data.mixes;
      state.tipo_deteriorantes = data.deteriorantes;
      state.parametros_ensayos = data.parametros;
      state.isLab = data.isLab;
      state.laboratorios = data.laboratories;
      state.programas_taag = data.programas_taag;
      state.controles = data.controles;
      state.infoUser = data.infoUser;
      state.canalesSoftwares = data.canalesSoftware;
      state.softwares = data.softwares;
      state.codigos_software = data.codigos_software;
    },
    setBitacoraData(state, data) {
      state.informacion_bitacora = data.info_bitacora;
      state.maxFiles = data.maxFiles;
      state.pocillos_bitacora = data.pocillos_bitacora;
      state.archivos_requeridos = data.files_needed;
    },
    setInfoBitacora(state, data) {
      state.informacion_bitacora = data;
    },
    setPosillosBitacora(state, data) {
      state.pocillos_bitacora = data;
    },
    setMaxFiles(state, data) {
      state.maxFiles = data;
    },
    setArchivos_requeridos(state, data) {
      state.archivos_requeridos = data;
    },
    setTipoInterpretacion(state, data) {
      state.tipoInterpretacion = data;
    },
    setEquipoLaboratorioSelected(state, data) {
      state.equipoLaboratorioSelected = data;
    },
    setProgramaSelected(state, data) {
      state.programaSelected = data;
    },
    setSelectedFiles(state, data) {
      state.selectedFiles = data;
    },
    setBitacora_id(state, data) {
      state.bitacora_id = data;
    },
    setPocillos_interpretacion(state, data) {
      state.pocillos_interpretacion = data;
    },
    setPrograma_id(state, data) {
      state.programa_id = data;
    },
    setShowAnalisis(state, data) {
      state.showAnalisis = data;
    },
    setShowEditResults(state, data) {
      state.showEditResults = data;
    },
    setBoolEditResults(state, data) {
      state.boolEditResults = data;
    },
    setFilesNamesSystem(state, data) {
      state.files_names_system = data;
    },
    setVersionFile(state, data) {
      state.versionFile = data;
    },
    setPocillosSelected(state, data) {
      state.pocillosSelected = data;
    },
    setPocilloDinamic(state, data) {
      state.pocilloDinamic = data;
    },
    setInterpretecionId(state, data) {
      state.id_interpretacion = data;
    },
  },
  actions: {
    actionLoadingModule({ commit, state }, data) {
      data = data || {
        text: '', active: false, percent: 0, showProgress: false,
      };
      const load = structuredClone(state.loadingModule);
      load.text = data.text;
      load.active = data.active;
      load.percent = data.percent;
      load.showProgress = data.showProgress;
      commit('setStateLoadingModule', load);
    },
    actionCatalogs({ commit, state }, data) {
      commit('setCatalogs', data);
    },
    actionBitacoraData({ commit, state }, data) {
      commit('setBitacoraData', data);
    },
    actionResetBitacoraData({ commit, state }, data) {
      commit('setBitacoraData', {
        info_bitacora: null,
        pocillos_bitacora: [],
        maxFiles: 0,
        files_needed: null,
        pocillos_interpretacion: [],
      });
    },
    actionInfoBitacora({ commit, state }, data) {
      commit('setInfoBitacora', data);
    },
    actionPosillosBitacora({ commit, state }, data) {
      commit('setPosillosBitacora', data);
    },
    actionMaxFiles({ commit, state }, data) {
      commit('setMaxFiles', data);
    },
    actionArchivos_requeridos({ commit, state }, data) {
      commit('setArchivos_requeridos', data);
    },
    actionTipoInterpretacion({ commit, state }, data) {
      commit('setTipoInterpretacion', data);
    },
    actionProgramaSelected({ commit, state }, data) {
      commit('setProgramaSelected', data);
    },
    actionEquipoLaboratorioSelected({ commit, state }, data) {
      commit('setEquipoLaboratorioSelected', data);
    },
    actionSelectedFiles({ commit, state }, data) {
      commit('setSelectedFiles', data);
    },
    actionBitacora_id({ commit, state }, data) {
      commit('setBitacora_id', data);
    },
    actionPocillos_interpretacion({ commit, state }, data) {
      commit('setPocillos_interpretacion', data);
    },
    actionPrograma_id({ commit, state }, data) {
      commit('setPrograma_id', data);
    },
    actionShowAnalisis({ commit, state }, data) {
      commit('setShowAnalisis', data);
    },
    actionShowEditResults({ commit, state }, data) {
      commit('setShowEditResults', data);
    },
    actionBoolEditResults({ commit, state }, data) {
      commit('setBoolEditResults', data);
    },
    actionFilesNamesSystem({ commit, state }, data) {
      commit('setFilesNamesSystem', data);
    },
    actionVersionFile({ commit, state }, data) {
      commit('setVersionFile', data);
    },
    actionPocillosSelected({ commit, state }, data) {
      commit('setPocillosSelected', data);
    },
    actionPocilloDinamic({ commit, state }, data) {
      commit('setPocilloDinamic', data);
    },
    actionInterpretecionId({ commit, state }, data) {
      commit('setInterpretecionId', data);
    },
  },
};
