const en = {
  'Tradicional Microbiology': 'Tradicional Microbiology',
  'Molecular Testing': 'Molecular Testing',
  'Chemical Testing': 'Chemical Testing',
  Other: 'Other',
  'Raw material': 'Raw material',
  'Finished product': 'Finished product',
  'Semi elaborated': 'Semi elaborated',
  'Fish and fishery products': 'Fish and fishery products',
  'Materia prima': 'Raw material',
  'Producto terminado': 'Finished product',
  'Semi elaborado': 'Semi elaborated',
  'Pescado y productos pesqueros': 'Fish and fishery products',

  'zone 1': 'Zone 1',
  'zone 2': 'Zone 2',
  'zone 3': 'Zone 3',
  'zona 1': 'Zone 1',
  'zona 2': 'Zone 2',
  'zona 3': 'Zone 3',

  Critical: 'Critical',
  'Not critical': 'Not critical',
  Crítica: 'Critical',
  'No crítica': 'Not critical',

  'Pre-sanitization': 'Pre-sanitization',
  'Post-sanitization': 'Post-sanitization',
  'In use': 'In use',
  'With gloves': 'With gloves',
  'Pre-sanitización': 'Pre-sanitization',
  'Post-sanitización': 'Post-sanitization',
  'En uso': 'In use',
  'Con guantes': 'With gloves',

  'Agua de Tratamiento': 'Treatment water',

  meses: {
    enero: 'January',
    febrero: 'February',
    marzo: 'March',
    abril: 'Abril',
    mayo: 'May',
    junio: 'June',
    julio: 'July',
    agosto: 'August',
    septiembre: 'September',
    octubre: 'Octubre',
    noviembre: 'October',
    diciembre: 'December',
  },
  submenu: {
    Workordersandrequests: 'Work orders and requests',
    createneworder: 'Create new order',
    requestslist: 'Requests list',
    preworkorderlist: 'Pre work order list',
    workorderlist: 'Work order list',
    completeworkorders: 'Complete work orders',
    samples: 'Samples',
    plantsandlocations: 'Plants and locations',
    laboratorytest: 'Laboratory tests',
    staffmanager: 'Staff manager',
    resultreport: 'Result report',
    accountinformation: 'Account information',
    subscriptions: 'Subscriptions',
    laboratories: 'Laboratories',
    samplingprogram: 'Sampling program',
    worksheet: 'Worksheet',
    worksheetMicro: 'Worksheet MB',

  },
  menu: {
    worksheet: 'Worksheet',
    worksheetMicro: 'Worksheet MB',
    analizando: 'Analyzing data',
    cargando: 'Loading',
    WorkOrdersyRequests: 'Work orders y requests',
    RequestsList: 'Requests list',
    Dismiss: 'Dismiss',
    crear_ot: 'Create new order',
    pre_ot: 'Pre-work order list',
    ot_muestra: 'Work order list',
    ot_com: 'Complete work orders',
    lote_medio: 'Reagent lot',
    analisis: 'PCR results analysis',
    ensayo: 'Lab tests',
    setting: 'Settings',
    language: 'Language',
    salir: 'Log out',
    aprende: 'Learning center',
    cerrar: 'Close',
    samples: 'Samples',
    Plants_locations: 'Plants and locations',
    Laboratory_test: 'Laboratory test',
    Users: 'User manager',
    Analysts_supervisors: 'Analysts and supervisors',
    Result_report: 'Result report',
    Account_information: 'Account information',
    staff: 'Staff manager',
    youplan: 'Your plan',
    labs: 'Laboratories',
    soli: 'Requests',
    soli_ingresada: 'Requests entered',
    soli_coo: 'Coordination of requests',
  },
  analisis: {
    btn_confirm: 'Confirm',
    enter_pass: 'Please enter your password to continue',
    no_equipo_compatible: 'This log is not compatible with the selected equipment',
    medio: 'Media',
    no_medio_and_equipo: 'It is necessary to select a team and an essay to continue',
    no_datos_bitacora: 'There is no log with this data',
    medioSelect: 'Select medium',
    abandonar: 'Do you want to leave the site?',
    interno: 'Internal',
    Reviewyouranalysisresultsfor: 'Review your analysis results for',
    medioanalisis: 'Medio',
    selectanalisis: 'Select Analisys',
    clase: 'Class',
    Clase: 'Class',
    well: 'Well',
    reason: 'REASON',
    Other: 'Other',
    DETERIORADO: 'SPOILAGE',
    spoilage: 'SPOILAGE',
    NODETERIORADO: 'NON-SPOILAGE',
    nonspoilage: 'NON-SPOILAGE',
    deteriorado: 'Spoilage',
    nodeteriorado: 'Non-spoilage',
    no_deteriorado: 'Non-spoilage',
    positivo: 'Positive',
    negativo: 'Negative',
    inhibido: 'Inhibited',
    vacio: 'Empty',
    zonagris: 'Gray zone',
    microorganismonoregistrado: 'Unregistered microorganism',
    alimentosdeusoinfantil: 'Baby food',
    carnesyproductoscarneos: 'Meats and meat products',
    pescadosyproductosdelapesca: 'Fish and fishery products',
    huevosyovoproductos: 'Eggs and egg products',
    salsasaderezosespeciasycondimentos: 'Sauces, dressings, spices and condiments',
    comidasyplatospreparados: 'Prepared meals and dishes',
    productosdepanaderiaypasteleria: 'Bakery and pastry products',
    frutasyverduras: 'Fruits and vegetables',
    bebidasyaguas: 'Drinks and water',
    estimulantesyfruitivos: 'Stimulating and fruitive',
    conservas: 'Preserves',
    noaplica: 'Does not apply',
    lechesyproductoslacteos: 'Milk and milk products',
    heladosymezclasparahelados: 'Ice cream and ice cream mixes',
    productosgrasos: 'Fatty products',
    caldossopascremasymezclasdeshidratadas: 'Broths soups creams and dehydrated mixtures',
    productoselaboradosapartirdecereales: 'Products made from cereals',
    azucaresymiel: 'Honey sugars',
    productosdeconfiteria: 'Confectionery products',
    agua: 'Water',
    aguas: 'Water',
    alimentos: 'Foods',
    ambientes: 'Environments',
    ambiente: 'Environments',
    manipuladores: 'Handlers',
    superficies: 'Surfaces',
    controlpositivo: 'Positive control',
    controlnegativo: 'Negative control',
    water: 'Water',
    foods: 'Foods',
    environments: 'Environments',
    handlers: 'Handlers',
    surfaces: 'Surfaces',
    positivecontrol: 'Positive control',
    negativecontrol: 'Negative control',
    toolp: 'Select as many wells as you wish, and then automatically assign certain parameters to them such as matrix, food category and sample sate.',
    analizar: 'Analyze',
    txt_1: 'Analyze your PCR results',
    txt_2: 'Upload your PCR raw file for an automatic interpretation of results',
    txt_3: 'Set up your lab test',
    txt_4: 'Set up your samples',
    txt_5: 'Enter samples manually',
    txt_6: 'Analyze a work order',
    matriz: 'Matrix',
    sub_matriz: 'Sub-matrix',
    sube: 'Upload your PCR file and we will analyze your results!',
    error: 'Error reading the structure of the selected file',
    mn: 'Sample not found',
    m: 'Sample found',
    formato_invalido: 'Invalid file format, Please enter a file with the extension',
    no_datos: 'No data found with OT',
    no_muestra: 'There are no samples with any Steriplex kit in the WO',
    bienvenido: 'Welcome',
    analiza: 'Analyze your results from',
    selecciona_equipo: 'Select your PCR test',
    carga: 'Upload your PCR file',
    Selecciona: 'Select your PCR equipment',
    selecciona_programa: 'Select your program',
    calcular_archivos: 'Calculate files',
    caracteres_alfanumericos: 'alphanumeric 1 a 300 characters',
    ingresa_numero_bitacora: 'Enter your worksheet number',
    ingresa: 'Type work order number',
    categoria: 'Category',
    revisar_ot: 'Search',
    analizar_resultados: 'Analyze results',
    forma_manual: 'Enter your samples manually',
    acciones: 'Actions',
    aplicar_matriz: 'Apply matrix',
    estado: 'Status',
    codigo_muestra: 'Sample code',
    estado_muestra: 'Sample status',
    ingresa_codigo: 'Enter code',
    tipo_muestra: 'Sample type',
    seleccionar_matriz: 'Select matrix',
    seleccionar_kit: 'Select category',
    seleccionar_status: 'Select status',
    seleccionar_ensayo: 'Select lab test',
    seleccionar_mix: 'Select mix',
    seleccionar_medio: 'Select media',
    seleccionar_sample_status: 'Select status',
    agregar_pocillos: 'Add wells',
    Resultados_analisis: 'Results of your analysis',
    muestras_publicadas: 'Samples were',
    muestras_publicadas_txt1: 'succesfully published,',
    muestras_publicadas_txt2: 'you can check them in ',
    sin_ingresar_muestra: 'No samples of',
    sin_ingresar_muestra_txt1: 'to publish',
    el_param: 'Parameter',
    el_param_txt1: 'of the sample',
    el_param_txt2: 'it was not published',
    la_muestra: 'The sample',
    la_muestra_txt1: 'was not published for having',
    la_muestra_txt2: 'Please rerun the PCR.',
    pocillo: 'Well',
    muestra: 'Sample ID',
    descripcion: 'Sample description ',
    sample_status: 'Sample status',
    origen: 'Origin',
    resultado: 'Result',
    otro: 'Other',
    generar_pdf: 'Generate PDF',
    not_found_pdf: 'Not found PDF',
    publicando_bitacora: 'Publishing worksheet',
    publicar: 'Publish',
    salir: 'Exit',
    m_resultados: 'Sample with results',
    m_resultados_txt1: 'The sample is already with results and published.',
    m_resultado_txt2: 'Do you want to post again?',
    confirmar_publicar_resultados: 'Are you sure you want to publish the results?',
    aceptar: 'Accept',
    cancelar: 'Cancel',
    publicar_resultados: 'Publish results',
    confirmar_regresar_analisis: 'Are you sure you want to return to results analysis?',
    // modal analisis
    _ot: 'Work order',
    la_ot_txt1: 'contains',
    la_ot_muestra_txt2: 'samples',
    la_ot_muestra_txt3: 'sample',
    seleccionar_kit_pcr: 'Select PCR kit',
    kit_pcr: 'PCR kit',
    Selectlaboratorytest: 'Select laboratory test',
    c_negativo: 'Negative control',
    c_negativo_simbolo: 'Control -',
    c_positivo: 'Positive control',
    c_positivo_simbolo: 'Control +',
    cePositivo: 'Positive extraction control',
    ceNegativo: 'Negative extraction control',
    cMedio: 'Media control positive',
    cnMedio: 'Media control negative',
    noResults: 'There are no results for these parameters',
    repetirReaccion: 'Profile error, repeat reaction',
    reaccionGris: 'Gray zone, repeat reaction',

    limpiar: 'Clear',
    txt1: "Don't forget to verify that each well assigned to your sample is associated with its corresponding lab test, and don't forget to include your controls.",
    guardar: 'Save',
    // kit ensayos
    aerobiosmesofilo: 'Mesophilic Aerobic',
    mesofilosanaerobios: 'Mesophilic Anaerobic',
    termofilos: 'Thermophilic aerobic',
    acidolacticas: 'Lactic Acid Bacteria',
    termoacidofilas: 'Thermoacidophiles',
    pool: 'Pool',
    lactobacillusspp: 'Lactobacillus sp',
    bitacora: 'Analyze by WorkSheet',
    analisis_sin_muestra: 'Analyze without samples',
    ensayo: 'Laboratory Test',
    control: 'Control',
    mix: 'Mix',
    sin_bitacora: 'Without worksheet',

    selecciona_categoria: 'Select category',

    // validacion controles
    confirmar_validacion_controles: 'Understood',
    next_controls_need_to_be_added: 'Next controls need to be added',
    not_samples: 'Not samples',
    no_samples_asigned: 'No samples assigned',
    incomplete_controls: 'Incomplete controls',
    samples_medium_without_assays: 'Samples with media without assays',

    has_controls_with_assays_not_used: 'Has controls with assays not used',

    continue_advance_deleted: 'If you continue, <br>the advance will be deleted',
    are_you_sure_overwrite_them: 'This worksheet already has samples published. <br>Do you want to overwrite them?',
    are_you_sure_overwrite_sample: 'This sample already has  published. <br>Do you want to overwrite them?',

    yes_overwrite: 'Yes, overwrite',

    // errores searchBitacora
    not_found_bitacora: 'Worksheet not found',
    some_assays_cant_use_equipment: 'Some tests cannot be used with this equipment',
    not_found_canales: 'Channels not found',
    some_assays_has_not_software_to_this_equipment: 'Some tests do not have software for this equipment',

    no_keep_current: 'No, keep current',
    reproceso: 'Reprocess',
    must_select: 'You must select',
    files: 'files',
    no_puede_cargar_mas_de: 'You cannot upload more than',
    archivos_invalidos: 'Invalid files',
    error_eliminar_archivo: 'Error deleting file',
    has_invalid_file: 'Has invalid file',
    warning: 'Warning',
    ningun_pocillo_seleccionado: 'No wells selected',
    no_muestras_bitacora: 'There are no samples in the worksheet',
    bitacora_publicada: 'Worksheet published',
    bitacora_publicada_parcialmente: 'Worksheet published partially',
    ocurrio_error_publicar: 'An error occurred while publishing the worksheet',
    tipo: 'Type',
    global: 'Global',
    range1: 'Range 1',
    range2: 'Range 2',
    an_error_occurred: 'An error occurred, please contact TI.',
    loading: 'loading...',
    directa: 'Direct',
    buscando_bitacora: 'Searching for worksheet',
    verificando_pocillos: 'Verifying wells',
    validando_archivos_subidos: 'Validating uploaded files',
    resultados_pocillos_empty: 'There are no results for the wells',
    error_get_results_interpretacion: 'Error getting results for interpretation',
    interpretando: 'Interpreting results',
    no_files_required: 'Files required',
    no_all_files_uploaded: 'Not all required files have been uploaded',

    tipo_muestra: 'Sample type',

    // warnings
    EMPTY_ENSAYO_MUESTRA: 'Empty sample test',
    NOT_FOUND_ASSAY: 'Test not found',
    IS_PUBLISH: 'Sample is published.',
    NOT_FOUND_SAMPLE_BITACORA: 'Sample not found in worksheet.',
    CANNOT_FIND_PARAMETERS_MIX: 'Could not find parameters for mix.',
    CANNOT_DELETED_RESULTS_PREVIOUS_ASSAY_STERIPLEX: 'Could not delete previous results.',
    CANNOT_DELETED_PARAMETERS_ASSAY_STERIPLEX: 'Could not delete previous parameters.',
    CANNOT_DELETED_PRERESULTS_ASSAY_STERIPLEX: 'Could not delete previous results.',
    RESULTADOS_EMPTY: 'Empty results.',
    NOT_FOUND_PARAMETERS_ASSAY: 'Test parameters not found.',
    NOT_FOUND_PARAMETER: 'Parameter not found.',
    NOT_ASING_PARAMETER_CUMPLE: 'Parameter not assigned to sample.',
    CANNOT_REACTIVE_PARAM_MUESTRA_OT: 'Parameter could not be activated',
    CANNOT_CREATE_PARAM_MUESTRA_OT: 'Parameter could not be created',
    CANNOT_DELETED_PRERESULT: 'Could not delete previous results for parameter',
    CANNOT_CREATE_PRERESULT: 'Could not create previous results for parameter',
    CANNOT_FIND_PRERESULTS_COMBINATION: 'The combination of results for the parameter was not found',
    CANNOT_UPDATE_PRERESULTS_COMBINATION_ERROR: 'Could not update the combination of results for the parameter',
    INCONGRUENT_COMBINATION_RESULTS: 'Incongruent combination of results',
    CANNOT_UPDATE_PRERESULTS_COMBINATION_REPROCESS_R1: 'Could not assign reprocess range 1 to parameter',
    ONLY_REPROCESS_R1: 'Only reprocess range 1 to parameter',
    CANNOT_UPDATE_PRERESULTS_COMBINATION_REPROCESS_R2: 'Could not assign reprocess range 2 to parameter',
    ONLY_REPROCESS_R2: 'Only reprocess range 2 to parameter',
    CANNOT_UPDATE_PRERESULTS_COMBINATION_REPROCESS_ALL: 'Could not send all parameter ranges to reprocess',
    REPROCESS_ALL: 'Send all parameter ranges to reprocess',
    REPROCESS_RANGES: 'Send ranges to reprocess',
    REPROCESS_GLOBAL: 'Reprocessing of the global parameter sample',
    WAITING_RESULTS_OTHER_RANGES: 'Waiting for results from the other parameter ranges',
    CANNOT_UPDATED_NO_COMBINATION_REPROCESO: 'Parameter could not be updated to reprocess',
    REPROCESS_PARAMETER: 'Parameter reprocessing',
    CANNOT_DESTROY_RESULT_PREVIOUS: 'Could not delete previous results of the parameter',
    CANNOT_PUBLISH_POSITIVE_RESULT: 'You do not have permission to publish positive result of the parameter',
    CANNOT_CREATE_RESULT: 'Could not save parameter result',
    NOT_FOUND_CONTROL_INTERNO_ASSAY: 'The internal control of the assay was not found',
    NOT_FOUND_MIXES_ASSAY: 'The test mixes were not found',
    CANNOT_UPDATE_PRERESULT_MIXES: 'Test result could not be updated',
    CANNOT_UPDATE_TO_PUBLISH_RESULTS_ENSAYO: 'Trial result could not be published',
    CANNOT_UPDATE_END_PUBLICATION_ENSAYO_MUESTRA: 'The assay could not be published',
    CANNOT_UPDATE_ESTATUS_BITACORA: 'Could not update log status',

    results_parametros_no_registrados: 'The results do not have parameters registered in the system.',

    muestra: 'Sample',
    ic_error: 'Error in the reaction',
    ic_ok: 'Internal Control meets established parameters',
    ic_inhibido: 'Inhibited or empty well',
    param_no_detectado: 'Not detected in the sample (Cq value below the detection limit)',
    param_presencia_sobrelimite: 'Presence in the sample with a Cq value above the quantification limit',
    param_no_cuantificable: 'Present in the sample but cannot be quantified',
    param_presencia: 'Presence',
    alto_riesgo: 'High Risk',
    bajo_riesgo: 'Low Risk',
  },
  ensayos: {
    descRequired: 'Control descriptions are required',
    NoControles: 'Controls is required',
    NoControlesNeg: 'Negative control is required',
    NoControlesPos: 'Positive control is required',
    UmbralRequired: 'Thresholds need to be configured for all controls',
    NoControls: 'No controls',
    controlListOptions: 'Options',
    controlListUmbrals: 'Thresholds',
    controlListDesc: 'Description',
    noUmbrals: 'Without thresholds',
    addDescMuestras: 'Add sample description',
    addControls: 'Add control',
    selectControls: 'Select controls',
    PleasefillControles: 'Please configure the corresponding controls',
    no_sub_options: 'No sub-options',
    no_results: 'No results found...',
    configCountResults: 'Set up count-type results',
    definirEnsayos: 'Define lab test ranges',
    rangosLeyenda: 'Define the ranges that the lab test will have',
    rango1: 'Range 1',
    rango2: 'Range 2',
    Rowsperpage: 'Rows per page:',
    viewunitsofmeasure: 'View units of measure',
    idioma: 'Language',
    Addconditional: 'Add conditional',
    Assignunitsofmeasure: 'Assign units of measure',
    Testname: 'Test name',
    Testcategory: 'Test category',
    Status: 'Status',
    Setuptestparameters: 'Set up test parameters',
    Setupandaddrelevant: 'Set up and add relevant information about the detection parameters of this test',
    Basicinformation: 'Basic information',
    UmbralesInformation: 'control configuration',
    Pleasefill: 'Please fill in the requested information to create your test',
    Classesoutofspec: 'Classes out of spec.',
    Selectcategory: 'Select category',
    Addcondition: 'Add condition',
    Informationrequired: 'Information required',
    warning: 'Warning',
    ConfigurarEnsayo: 'Lab test settings',
    Matrices: 'Matrix',
    clasesnocumplen: 'Classes that do not meet',
    Clases: 'Classes',
    Addthresholds: 'Add thresholds',
    cusromparam: 'Edit parameters',
    labs: 'Laboratories',
    costo: 'Cost',
    cod_ensayo: 'Test code',
    tiempo_result: 'Time to obtain results (hr)',
    tiempo_incub: 'Incubation time (hr)',

    activite: 'Activate TAAG tests',
    active_lab: 'Activate TAAG laboratory tests',
    txt_kit: "Add laboratory tests compatible with TAAG Genetics' real-time PCR kits",
    add: 'Add',
    Addparameter: 'Add parameter',
    bajo: 'Low',
    alto: 'High',
    alto_riesgo: 'High Risk',
    bajo_riesgo: 'Low Risk',
    pathogeno: 'Pathogen',
    indicador: 'Indicator',
    deteriorante: 'Deteriorant',
    cerrar: 'Close',

    // modal- param condition
    txt_param_condition: 'Parameter conditions',
    t_param: 'Parameter',
    classifitacion: 'Classification',
    RiskLevel: 'Risk level',
    you_param: 'Your parameters',
    add_param: 'Parameter name',

    // modal-tipo ensayo

    txt_title: 'Set up parameters',
    test: 'Test',
    tezt_category: 'Test category',
    tipo_test: 'Type test name',
    category: 'Select test category',

    // modal-configuracion recuento

    title: 'Count-type results settings',
    title2: 'Please select the numeric notation in which your count-type results will be reported',

    // txt- tooltpis
    txt_namerepot: 'This name will appear in the report.',
    txt_t_ensayo: 'Here you select, or create, a test type, i.e. Molecular biology, traditional testing, etc.',
    txt_desc: 'This description will appear in the report.',
    txt_metho: 'This methodology will appear in the report.',
    txt_param: 'This is what your test will detect., e.g., Staphylococcus aureus, Copper Levels, Total coliforms, etc. You can add as many parameters as you wish according with the limitations of your test.',
    txt_t_tecnica: 'If you selected Traditional microbiology, Molecular testing or Rapid testing, we would like you to add additional information about your parameters such as risk level or classification, so we can refine our algorithms for an optimal analysis.',
    txt_result: 'This is how you will inform your results.',

    ensayos_lab: 'Laboratory tests',
    crear_edita: 'Create, edit, and manage laboratory tests',
    buscar: 'Search',
    crear_nuevo_ensayo: 'Add a new lab test',
    mantebedor: 'Matriz maintainer',
    resumen: 'Test summary',
    ningun_registro: 'No record found',
    r_pagina: 'Record per page',
    inactivo: 'Inactive',
    activo: 'Active',
    detalle: 'View detail',
    mantenedor: 'Parameter maintainer',
    crear_param: 'Create parameters',
    guardar: 'Save',
    guardar_ensayo: 'Save test',
    param_manager: 'Parameter manager',
    nombre: 'Test name',
    tipo: 'Type',
    param_mtz: 'Parameters and matrixes',
    option: 'Option',
    creado: 'Creation date',
    clasif: 'Clasification',
    rules_txt0: 'Name test is required',
    rules_incub: 'The incubation time cannot be greater than the result time',
    rules_txt1: 'Test type is required',
    rules_txt2: 'Methodology type is required',
    rules_txt3: 'Site analysis is required',

    rules_txt4: 'The product is required',
    rules_txt5: 'You have to select at least one parameter. If there are no available parameters in the dropdown list, please add them in the parameter manager.',
    rules_txt6: 'The result type is required',
    rules_txt7: 'The type of kit is required',
    rules_txt8: 'The description of the lab test is required',
    rules_txt9: 'The methodology is required',
    rules_txt10: 'You must select a company',
    rules_txt11: 'Result time is required ',
    rules_txt12: 'Technical type is required',
    rules_txt13: 'The name of the trial is required',

    rules_txt14: 'Threshold is required',
    rules_txt15: 'Sample type is required',
    rules_txt_condicionparam: 'Selected parameters and conditions',
    rules_txt_condicionparamrangos: 'Select at least one range for each parameter',

    // modal
    param_matrices: 'Parameters and matrixes',
    matrices: 'Matrix',
    param: 'Parameter',
    editar: 'Edit',
    cancelar: 'Cancel',
    opciones: 'Options',

    // cierre modal

    agregar_ensayo: 'Add new laboratory test',
    nombre_ensayo: 'Test name',
    estado: 'Test status',
    tipo_ensayo: 'Test type',
    Name_tipo_ensayo: 'Name test type',
    info_tecnicas: 'Technical information about your lab test',

    clasif_ensayo: 'Trial classification',
    desc: 'Description (max. 200 characters)',
    testnamea: 'Test name',
    activado: 'Active',

    metodologia: 'Methodology',
    txt1: 'It was not found',
    txt2: 'Hit enter to create.',
    tipo_tecnica: 'Parameter specification',
    tipo_resultado: 'Result type',
    asignacion: 'Assign matrixes',
    select: 'Select the type of samples / matrixes that will be analyzed with this test',
    unidades: 'Type unit of measure',
    thparam: 'Parameter',
    thumbral: 'Threshold',
    thunidad: 'Units',

    definir: 'Define detection thresholds and units of measure',
    selectumbrales: 'Select the type of samples / matrixes that will be analyzed with this test',
    umbral: 'Type threshold value',
    unidad_medida: 'Measurement units',
    alimento: 'Food',
    superficies: 'Surfaces',
    manipuladores: 'Handlers',
    aguas: 'Water',
    ambientes: 'Environments',
    txt3: 'Thresholds do not apply for the result type "Absence / Presence"',

    PA: 'Presence Absence',
    RTO: 'Coun',
    Ext: 'Exeternalized',
    SC: 'Semi Quantification',

    rules_txt_condicion: 'Selected level and classification',
    update_succes: 'Updated successfully',
    save_succes: 'Saved successfully',
    selectedmatriz: 'Select a matrix',
    delete_param: 'Parameter removed',
    save_param: 'Saved parameter',
    // modal eliminar ensayo
    delete_title: 'Warning',
    delete_cuestion: 'Are you sure you want to delete this test \n',
    delete_cuestion2: 'This cannot be undone.',
    activekits: 'Active kits Taag',
    listakits: 'List kits',

    umbralesalimento: 'Undefined thresholds in food',
    umbralessuperficie: 'Thresholds not defined in surface',
    umbralesmanipuladores: 'Undefined thresholds in handlers',
    umbralesagua: 'Undefined thresholds in water',
    umbralesambiente: 'Undefined thresholds in environments',

    validacion_rangos: 'Rank A is greater than rank B',
    agregar_rango: 'Add ranks',
    // opciones
    editar_ensayo: 'Edit test',
    eliminar_ensayo: 'Delete test',
  },
  matrices: {
    Alimento: 'Food',
    Alimentos: 'Food',
    Aguas: 'Water',
    Agua: 'Water',
    Superficies: 'Surface',
    Manipuladores: 'Handlers',
    Ambientes: 'Environment',
  },
  modal_tipo_ensayo: {
    TradicionalMicrobiology: 'Tradicional microbiology',
    MolecularTesting: 'Molecular testing',
    RapidTesting: 'Rapid testing',
    ChemicalTesting: 'Chemical testing',
    Other: 'Other',
  },
  tipoResul: {
    PresenceAbsence: 'Presence absence',
    Count: 'Count',
    Exeternalized: 'Open input',
    SemiCuantificación: 'Semi Quantification',
  },
  submatriz: {
    MateriaPrima: 'Raw material',
    ProductoTerminado: 'Finished product',
    SemiElaborado: 'Semi elaborated',
    zona1: 'zone 1',
    zona2: 'zone 2',
    zona3: 'zone 3',
    critica: 'Critical',
    nocritica: 'Not critical',
    presanitizacion: 'Pre-sanitization',
    postsanitizacion: 'Post-sanitization',
    enuso: 'In use',
    guante: 'With gloves',
  },
  solicitudes: {
    fechaasignado: 'Date assigned',
    Horainicio: 'Start time',
    Horatermino: 'End time',
    Success: 'Success',
    Eventoeliminadocorrectamente: 'Event removedsuccessfully',
    Advertencia: 'Warning',
    Deseaeliminarestasolicitud: 'Do you want to delete this event',
    aceptar: 'Accept',
    Max200characters: 'Max 200 characters',
    fecha: 'Date',
    horainicio: 'Start time',
    horatermino: 'End time',
    Asunto: 'Subject',
    Descripcion: 'Description',
    Eliminar: 'Delete',
    Editar: 'Edit',
    Ingresarcomentarionomayora200caracteres: 'Enter a comment of no more than 200 characters',
    success: 'Success',
    success1: 'The changes were made correctly',
    mostrar: 'Show requests',
    ocultar: 'Hide requests',
    ingresada: 'Requests entered',
    cliente: 'Client',
    planta: 'Plant',
    muestreador: 'Sampler',
    fecha_creacion: 'Creation date',
    fecha_programada: 'Scheduled date',
    estado: 'Status',
    detalle: 'Detail',
    encargado: 'Local manager',
    fecha_presunta: 'Presumed date',
    dir: 'Address',
    materia_prima: 'Raw material',
    f_elab: 'Elaboration date',
    f_ven: 'Expiration date',
    parametros: 'Parameters',
    opciones: 'Options',
    // modalensayo
    title_ensayo: 'LAB TEST TO PERFORM',
    muestra: 'Sample',
    n_ensayo: 'Test name',
    desc: 'Description',
    lote: 'Lot',
    revisar: 'Review',

    // modaledit
    title_edit: 'Sample editor',

    // coordinacion
    coo: 'Request list',
    ordenar: 'Sort by',
    total_soli: 'TOTAL REQUESTS',
    hoy: 'Today',
    dia: 'Day',
    semana: 'Week',
    mes: 'Month',
    dias4: '4 Day',

    // MODAL COORDINACION
    EditarEvento: 'Edit event',
    crear_evento: 'Create event',
    asunto: 'Business',
    resumen_solicitado: 'Summary requested',
    direccion: 'Address',
    resumen_muestra: 'Sample summary',
    cancelar: 'Cancel',
    obvs: 'Observations',
    codigo_barras: 'codebar',
    DetallesolicitudID: 'Request ID detail',
    Detallesolicitud: 'Request detail',
    deletesoli: 'Delete request',
    Editarsolicitud: 'Edit Request',
    servicio: 'Service',
    guardar: 'Save',
    Desearealizarloscambios: 'You want to make the changes',
    region: 'region',
    manipuladores: 'Handlers',
    agua: 'Water',
    ambientes: 'Environments',
    superficies: 'Surfaces',
    alimentos: 'Foods',
  },
  uploadfile: {
    ControldeVersiones: 'Version Control',
    Clientes: 'Clients',
    Todos: 'All Clients',
    Ensayo: 'Lab test',
    versionactualizada: 'Version updated',
    Zipfile: 'Zip file',
    Version: 'Version',
    NombredelCarpetaprincipal: 'Parent Folder Name',
    Nombredelarchivoprincipal: 'Name of the main file',
    Clear: 'Clear',
    Submit: 'Submit',
    Historial: 'History',
    showall: 'Show all',
    Matrices: 'Matrix',
    Archivo: 'file',
    Ruta: 'path',
    RutaBackup: 'Backup Route',
    Activo: 'Active',
    Creado: 'Created',
    Cliente: 'Client',
    Ensayos: 'Lab tests',
  },

  continue: 'Continue',
};

export default en;
